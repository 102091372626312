<template>
  <ul
    class="w-100 tree-node p-0"
  >
    <!--  selected -->
    <template v-for="(node, index) in folder">
      <li
        v-if="move ? node.is_folder : true"
        :key="node.id"
        v-b-toggle="`collapse-${node.id}`"
        :draggable="true"
        class="w-100 py-10 position-relative tree-content"
        @click="onClick(node)"
        @drop.prevent="dragFile($event, node)"
        @dragover.prevent="dragHandler"
      >
        <div
          v-if="node.is_folder"
          v-b-toggle="`collapse-${node.id}`"
          class="pr-50"
          @click.stop
        >
          <feather-icon
            :icon="collapse[index] ? 'ChevronDownIcon' : 'ChevronRightIcon'"
            size="20"
          />
        </div>

        <div
          :title="node.is_folder ? '' : node.file ? '' : node.url"
          class="file-text pr-3"
        >
          <feather-icon
            :class="node.is_folder ? 'text-warning' : node.file ? '' : 'text-primary'"
            :icon="node.is_folder ? 'FolderIcon' : node.file ? node.data_type[1] : 'LinkIcon'"
            size="20"
            class="mr-50"
          />
          <span>{{ node.is_folder ? node.title : node.file ? node.file_name : 'url' }}</span>
        </div>
        <template v-if="!node.is_folder">
          <b-button
            v-if="allOptions"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle position-absolute bl"
            @click.stop="$emit('delete-folder', node.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="20"
            />
          </b-button>
          <template v-else>
            <b-button
              v-ripple.400="'rgba(0, 207, 232, 0.15)'"
              variant="flat-info"
              class="btn-icon rounded-circle position-absolute bl"
              @click.stop="$emit('to-right', node, node.id)"
            >
              <feather-icon
                icon="ArrowRightIcon"
                size="20"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-success"
              class="btn-icon rounded-circle position-absolute bl position"
              @click.stop="$emit('show-file', node)"
            >
              <feather-icon
                icon="EyeIcon"
                size="20"
              />
            </b-button>
          </template>
        </template>
        <template v-else>
          <b-button
            v-if="move"
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle position-absolute bl"
            @click.stop="$emit('move-folder', node, node.id)"
          >
            <feather-icon
              icon="CheckIcon"
              size="20"
            />
          </b-button>
          <b-dropdown
            v-if="allOptions"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-warning"
            class="dropdown-icon-wrapper position-absolute bl1"
            toggle-class="rounded-circle p-25"
            toggle-tag="span"
            @click.stop=""
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle"
              />
            </template>
            <b-dropdown-item
              v-if="addFolderShow"
              @click.stop="$emit('folder-add-btn', node.id, false)"
            >
              <feather-icon icon="FolderPlusIcon" />
            </b-dropdown-item>
            <b-dropdown-item
              v-if="addFileShow"
              @click.stop="$emit('file-add-btn', node.id, true)"
            >
              <feather-icon icon="FilePlusIcon" />
            </b-dropdown-item>
            <b-dropdown-item
              v-if="editFolderShow"
              @click.stop="$emit('edit-btn', node)"
            >
              <feather-icon icon="Edit2Icon" />
            </b-dropdown-item>
            <b-dropdown-item
              v-if="deleteFolderShow"
              @click.stop="$emit('delete-folder', node.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </li>
      <b-collapse
        v-if="node.children"
        :id="`collapse-${node.id}`"
        :key="`coll${index}`"
        v-model="collapse[index]"
        class="pl-20"
        @click="openOrClose(index)"
      >
        <tree-collapse
          :add-file-show="addFileShow"
          :add-folder-show="addFolderShow"
          :delete-folder-show="deleteFolderShow"
          :edit-folder-show="editFolderShow"
          :all-options="allOptions"
          :folder="node.children"
          :move="move"
          :file="node.get_file_content"
          @show-file="(file) => $emit('show-file', file)"
          @to-right="(file, id) => $emit('to-right', file, id)"
          @save-draged-file="(file, id) => $emit('save-draged-file', file, id)"
          @click="(node) => $emit('click', node)"
          @move-folder="(file, id) => $emit('move-folder', file, id)"
          @folder-add-btn="(node) => $emit('folder-add-btn', node, false)"
          @file-add-btn="(node) => $emit('file-add-btn', node, true)"
          @edit-btn="(node) => $emit('edit-btn', node)"
          @delete-folder="(id) => $emit('delete-folder', id)"
        />
      </b-collapse>
    </template>
  </ul>
</template>

<script>
import {
  BCollapse,
  BDropdown,
  BDropdownItem,
  BButton,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  name: 'TreeCollapse',
  components: {
    BCollapse,
    BDropdown,
    BButton,
    BDropdownItem,
    FeatherIcon,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    folder: {
      type: Array,
      default: () => [],
    },
    file: {
      type: Array,
      default: () => [],
    },
    addFileShow: {
      type: Boolean,
      default: true,
    },
    addFolderShow: {
      type: Boolean,
      default: true,
    },
    deleteFolderShow: {
      type: Boolean,
      default: true,
    },
    editFolderShow: {
      type: Boolean,
      default: true,
    },
    allOptions: {
      type: Boolean,
      default: true,
    },
    move: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
      active_trees: [],
      collapse: [],
      file1: [],
    }
  },

  methods: {
    onClick(node) {
      this.$emit('click', node)
    },
    // onOpenCollapse(node) {
    //   // eslint-disable-next-line no-param-reassign
    //   node.active = true
    //   this.$forceUpdate()
    // },
    // onHideCollapse(node) {
    //   // eslint-disable-next-line no-param-reassign
    //   node.active = false
    //   this.$forceUpdate()
    // },
    openOrClose(index) {
      this.collapse[index] = !this.collapse[index]
      // @show="onOpenCollapse(node)"
      // @hide="onHideCollapse(node)"
    },

    showFile(item) {
      const title = item.split('/')
      return title[title.length - 1]
    },
    uploadFile(e) {
      this.File = e.target.files
    },
    dragHandler() {
      // console.log(e)
    },
    dragFile(e, item) {
      // console.log(e)
      this.file1 = e.dataTransfer.files
      if (item.is_folder) {
        if (this.file1[0].type.length) {
          this.$emit('save-draged-file', this.file1[0], item.id)
        }
      }
    },
  },
}
</script>
<style lang="scss">
.vh-90 {
  height: 80vh;
}
.py-10 {
  padding: 8px 0 !important;
}
.bl {
  right: 0;
}
.bl1 {
  top: 0;
  right: 0;
}
.bl2 {
  padding: 10px;
  border-radius: 50%;
}
.min-h {
  min-height: 200px;
}
.aaa {
  width: 100%;
  aspect-ratio: 16 / 9 !important;
}
.tree-content {
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}
.selected {
  background-color: #e7eef7;
}
.tree-node:not(.selected) > .tree-content:hover {
  background: #f6f8fb;
  color: #000!important;
}
.tree-node {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
}
.pl-20 {
  padding-left: 24px;
}
.position {
  right: 41px!important;
}
.file-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
}
</style>
