<template>
  <b-modal
    id="set-audio-way"
    :title="$t('tests.general_settings')"
    size="lg"
    :ok-title="$t('general.save')"
    :cancel-title="$t('general.cancel')"
    no-close-on-backdrop
    @ok.prevent="saveClick"
    @shown="onShow"
    @hide="onHide"
  >
    <b-row>
      <b-col md="12">
        <div
          v-if="new_audio_track"
          class="border p-1 d-flex justify-content-between align-items-center"
        >
          <span>
            {{ new_audio_track.name }}
          </span>
          <b-button
            class="p-50"
            variant="danger"
            @click="removeAudioTrack"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
        <b-form-group :label="$t('tests.audio_track')">
          <b-button
            v-b-modal.select-audio-track
            block
            variant="warning"
          >
            <feather-icon icon="PlusIcon" />
            {{ new_audio_track ? $t('tests.change_audio_track') : $t('tests.add_audio_track') }}
          </b-button>
        </b-form-group>
      </b-col>
      <!-- <b-col md="12">
        <b-form-group>
          <b-form-checkbox
            v-model="options.questions_shuffle"
            inline
          >
            {{ $t('tests.shuffle_questions') }}
          </b-form-checkbox>
        </b-form-group>
      </b-col> -->
      <!-- <b-col md="12">
        <b-form-group>
          <b-form-checkbox
            v-model="options.answers_shuffle"
            inline
          >
            {{ $t('tests.shuffle_answers') }}
          </b-form-checkbox>
        </b-form-group>
      </b-col> -->
    </b-row>
    <content-select-modal
      modal-id="select-audio-track"
      @fileSelected="audioTrackSelected"
    />
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BButton,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import contentSelectModal from '../../AddTest/components/modals/contentSelectModal.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    // BFormCheckbox,
    BButton,
    contentSelectModal,
  },
  props: {
    defOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      new_audio_track: null,
      options: this.defOptions,
    }
  },
  watch: {
    defOptions() {
      this.options = this.defOptions
    },
  },
  methods: {
    ...mapActions('content', ['FETCH_ONE_FOLDER']),
    saveClick() {
      this.$emit('save', this.options)
    },
    audioTrackSelected(item) {
      this.new_audio_track = { name: item.url.split('/').reverse()[0] }
      this.$bvModal.hide('select-audio-track')
      this.options.audio_track = item.url ?? item.file
      this.options.audio_id = item.id
    },
    removeAudioTrack() {
      this.new_audio_track = null
      this.options.audio_track = null
    },
    onShow() {
      if (this.options.audio_id) {
        this.FETCH_ONE_FOLDER(this.defOptions.audio_id).then(res => {
          this.new_audio_track = { name: res.file_name }
        })
      } else if (this.defOptions.audio_track) {
        this.new_audio_track = { name: this.defOptions.audio_track.split('/').reverse()[0] }
      }
    },
    onHide() {
      this.options = null
      this.new_audio_track = null
    },
  },
}
</script>

<style>

</style>
