<template>
  <b-modal
    id="ShowContent"
    centered
    size="lg"
    no-close-on-backdrop
    :ok-title="$t('general.close')"
    ok-only
    :title="$t('courses.add_content')"
    @hide="onHide"
  >
    <div
      class="vh-70"
    >
      <all-doc-viewer :src="url" />
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import AllDocViewer from '@/components/AllDocViewer.vue'

export default {
  components: {
    BModal,
    AllDocViewer,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      url: '',
    }
  },
  watch: {
    src(val) {
      this.$nextTick(() => {
        this.url = val
      })
    },
  },
  methods: {
    onHide() {
      this.$emit('hide')
    },
  },
}
</script>

<style>
.vh-70 {
  height: 70vh!important;
}
</style>
