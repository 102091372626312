<template>
  <b-modal
    id="lesson-settings-modal"
    :title="$t('tests.general_settings')"
    :ok-title="$t('general.save')"
    :cancel-title="$t('general.cancel')"
    no-close-on-backdrop
    @ok="saveClick"
  >
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('tests.attempts')"
          label-for="attempts"
        >
          <b-form-input
            id="attempts"
            v-model="options.attempts"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('courses.lessons_order_type')"
          label-for="lesson_order_type"
        >
          <v-select
            v-model="options.order_type"
            :options="$data.$_lessons_view_order"
            :reduce="opt => opt.key"
            :get-option-label="opt => opt.title[$i18n.locale]"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  props: {
    defOptions: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      options: this.defOptions,
    }
  },
  watch: {
    defOptions() {
      this.options = this.defOptions
    },
  },
  methods: {
    saveClick() {
      this.$emit('save', this.options)
    },
  },
}
</script>

<style>

</style>
