<template>
  <div>
    <b-tabs lazy>
      <template #tabs-start>
        <b-button
          v-b-modal.lesson-settings-modal
          class="py-50 mr-2 px-1"
          variant="info"
          @click="openLessonSettingsModal"
        >
          <feather-icon
            size="24"
            icon="SettingsIcon"
          />
        </b-button>
      </template>
      <template #tabs-end>
        <b-button
          v-b-modal.test-part-modal
          class="py-50 px-1"
          variant="outline-primary"
          @click="openNewPartModal"
        >
          <feather-icon
            size="24"
            icon="PlusIcon"
          />
        </b-button>
      </template>
      <template
        v-for="(part, index) in allData.objects"
      >
        <b-tab
          v-if="!part.is_deleted"
          :key="index"
        >
          <template #title>
            {{ part.title }}
            <b-button
              v-b-modal.test-part-modal
              class="p-50 ml-50"
              variant="white"
              @click="editPart(part, index)"
            >
              <feather-icon
                class="text-warning mr-0"
                icon="EditIcon"
              />
            </b-button>
            <b-button
              class="p-50"
              variant="white"
              @click="deleteTab(part.id, index)"
            >
              <feather-icon
                class="text-danger mr-0"
                icon="XIcon"
              />
            </b-button>
          </template>
          <b-card>
            <!-- <b-button-group class="mb-2">
            <b-button
              class="border-radius-0"
              :variant="card? 'outline-secondary' : 'primary'"
              @click="check(false)"
            >
              {{ $t('courses.content') }}
            </b-button>
            <b-button
              class="border-radius-0"
              :variant="card? 'primary' : 'outline-secondary'"
              @click="check(true)"
            >
              {{ $t('courses.test') }}
            </b-button>
          </b-button-group> -->
            <doc-editor
              v-model="allData.objects[index].description"
            />
          </b-card>

          <b-card>
            <b-row>

              <b-col
                class="text-right mb-1"
                cols="12"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mr-2"
                  @click="SetAudio(index)"
                >
                  <feather-icon
                    icon="PlusIcon"
                  />
                  {{ $t('tests.audio_track') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="addCourse(index)"
                >
                  <feather-icon
                    icon="PlusIcon"
                  />
                  {{ $t('general.add') }}
                </b-button>
              </b-col>
              <b-col cols="12">

                <b-table
                  hover
                  responsive
                  show-empty
                  :fields="fields"
                  :items="allData.objects[index].contents"
                  style-class="slots_text_align vgt-table bordered"
                >
                  <template #head()="{ label }">
                    {{ $t(label) }}
                  </template>
                  <template
                    #empty
                  >
                    <div class="text-center text-secondary">
                      {{ $t('general.no_data_to_view') }}
                    </div>
                  </template>
                  <template #cell(title)="data">
                    {{ data.item.file ? data.item.file_name : data.item.url }}
                  </template>
                  <template #cell(type)="data">
                    <b-badge :variant="data.item.file ? 'success' : 'primary'">
                      {{ data.item.file ? 'File' : 'Url' }}
                    </b-badge>
                  </template>
                  <template
                    #cell(actions)="data"
                  >
                    <div class="d-flex align-items-center justify-content-center">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="btn-icon p-25"
                        size="sm"
                        @click="deleteContent(data, index)"
                      >
                        <feather-icon
                          size="22"
                          icon="Trash2Icon"
                        />
                      </b-button>
                    </div>
                  </template>

                </b-table>
              </b-col>
            </b-row>

          </b-card>
        </b-tab>
      </template>
    </b-tabs>

    <b-col
      cols="12"
      class="text-right mt-3"
    >

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        @click="saveContent"
      >
        {{ $t('general.save') }}
      </b-button>
    </b-col>
    <AddContentModal
      :def-selectet="defSelectet"
      @saveContent="save"
    />
    <defoult-add-modal
      id="tabAddModal"
      :edit-data="editedData"
      :no-lang="true"
      :is-edit="isEdit"
      @add-button="onAddTab"
    />
    <lesson-settings-modal
      :def-options="lesson_options"
      @saveClick="lesson_options = $event"
    />
    <set-audio-way-modal
      :def-options="defOptions"
      @save="saveAudio"
    />
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BCol,
  BRow,
  BBadge,
  // BButtonGroup,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AddContentModal from './components/AddContentModal.vue'
import DefoultAddModal from '@/components/DefoultAddModal.vue'
import docEditor from '@/components/documentCKEditor.vue'
import lessonSettingsModal from './components/lessonSettingsModal.vue'
import SetAudioWayModal from './components/SetAudioWayModal.vue'

export default {
  components: {
    BTable,
    BCard,
    BCol,
    BRow,
    BButton,
    BBadge,
    // BButtonGroup,
    AddContentModal,
    BTabs,
    DefoultAddModal,
    BTab,
    docEditor,
    lessonSettingsModal,
    SetAudioWayModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      allData: {
        objects: [],
      },
      defOptions: {
        audio_track: '',
        index: null,
      },
      defSelectet: [],
      card: false,
      itemIndex: null,
      editedData: {},
      isEdit: false,
      lesson_options: {},
      fields: [
        {
          label: 'general.title',
          key: 'title',
        },
        {
          label: 'courses.type',
          key: 'type',
        },
        {
          label: 'general.actions',
          key: 'actions',
          thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_LESSON_CONTENT', 'GET_LESSON', 'GET_ONE_COURSE', 'GET_ONE_CHAPTER']),
  },
  async mounted() {
    await this.FETCH_ONE_COURSE(this.$route.params.id)
    await this.FETCH_LESSON(this.$route.params.themeId)
      .then(() => {
        if (this.GET_LESSON.is_draft) {
          this.allData.objects = this.GET_LESSON.lesson_relation_draft
        } else if (this.GET_LESSON.lesson_relation.length) {
          // eslint-disable-next-line prefer-destructuring
          this.allData.objects = this.GET_LESSON.lesson_relation
        } else {
          this.allData.objects = [{
            title: 'part 1',
            contents: [],
            description: '',
            draft_id: null,
            options: {
              audio_track: '',
            },
          }]
        }
      })

    await this.FETCH_ONE_CHAPTER(this.GET_LESSON.chapter)
    this.updateBreadcrumb()
  },
  methods: {
    ...mapActions('courses', ['FETCH_LESSON_CONTENT', 'CREATE_LESSON_CONTENT', 'UPDATE_LESSON_CONTENT', 'FETCH_LESSON', 'UPDATE_LESSON',
      'DELETE_LESSON_CONTENT', 'FETCH_ONE_COURSE', 'FETCH_ONE_CHAPTER', 'UPDATE_COURSE_RELATION']),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    addCourse(index) {
      this.itemIndex = index
      this.defSelectet = this.allData.objects[index].contents
      this.$nextTick(() => {
        this.$bvModal.show('AddContent')
      })
    },
    saveAudio(e) {
      this.allData.objects[e.index].options = { audio_track: e.audio_track, audio_id: e.audio_id }
      this.defOptions = {
        audio_track: '',
        index: null,
      }

      this.$nextTick(() => {
        this.$bvModal.hide('set-audio-way')
      })
    },
    SetAudio(index) {
      this.defOptions = {
        index,
        audio_track: this.allData.objects[index]?.options?.audio_track,
        audio_id: this.allData.objects[index]?.options?.audio_id ? this.allData.objects[index]?.options?.audio_id : null,
      }
      this.$nextTick(() => {
        this.$bvModal.show('set-audio-way')
      })
    },
    check(type) {
      this.card = type
    },
    save(list) {
      this.allData.objects[this.itemIndex].contents = list
    },
    refresh() {
      this.FETCH_LESSON(this.$route.params.themeId)
        .then(() => {
          // eslint-disable-next-line prefer-destructuring
          this.allData.objects = this.GET_LESSON.lesson_relation
        })
    },
    saveContent() {
      const data = this.$_removeObjectConnection(this.allData)
      data.objects = data.objects.map(({ id, contents, ...rest }) => ({
        id: id || null,
        contents: contents.map(e => e.id),
        ...rest,
      }))
      this.$set(data, 'lesson', Number(this.$route.params.themeId))
      this.UPDATE_COURSE_RELATION(data)
      // this.CREATE_LESSON_CONTENT(data)
        .then(() => {
          this.$_successToast(this.$t('general.saved'))
          this.UPDATE_LESSON({ id: this.$route.params.themeId, options: this.lesson_options }).then(() => {
            this.$_successToast(this.$t('general.saved'))
            this.$router.push(`/course/course-administrations/theme-list/${this.$route.params.id}`)
          })
        })
        .catch(() => {
          this.$_errorToast(this.$t('general.error'))
        })
    },
    deleteContent(item, index) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.allData.objects[index].contents.splice(item.index, 1)
        }
      })
    },
    deleteTab(id, index) {
      // this.$_showAreYouSureModal().then(value => {
      //   if (value) {
      // if (id) {
      //   this.DELETE_LESSON_CONTENT(id)
      //     .then(() => {
      //       this.$_successToast(this.$t('general.removed'))
      //       this.$set(this.allData.objects[index], 'is_deleted', true)
      //       // this.allData.objects.splice(index, 1)
      //     })
      //     .catch(() => {
      //       this.$_errorToast(this.$t('general.error'))
      //     })
      // } else {
      // this.allData.objects.splice(index, 1)
      this.$set(this.allData.objects[index], 'is_deleted', true)
      // this.$_successToast(this.$t('general.removed'))
      // }
      //   }
      // })
    },
    showFile(item) {
      const title = item.split('/')
      return title[title.length - 1]
    },

    updateBreadcrumb() {
      const [first, second, third, fourth, fifth] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      second.text = this.GET_ONE_COURSE.title
      second.to = `/course/course-administrations/theme-list/${this.GET_ONE_COURSE.id}`
      second.active = false
      third.text = this.GET_ONE_CHAPTER.title
      fourth.text = this.GET_LESSON.title
      this.UPDATE_BREADCRUMB([first, second, third, fourth, fifth])
    },
    openNewPartModal() {
      this.isEdit = false
      this.$nextTick(() => {
        this.$bvModal.show('tabAddModal')
      })
    },
    editPart(data, index) {
      this.itemIndex = index
      this.isEdit = true
      this.editedData = data
      this.$nextTick(() => {
        this.$bvModal.show('tabAddModal')
      })
    },
    onAddTab({ title }) {
      if (this.isEdit) {
        this.allData.objects[this.itemIndex].title = title
      } else {
        this.allData.objects.push(
          {
            title,
            contents: [],
            description: '',
            draft_id: null,
            options: {
              audio_track: '',
            },
          },
        )
      }
      this.$bvModal.hide('tabAddModal')
    },
    openLessonSettingsModal() {
      this.lesson_options = this.GET_LESSON.options || {}
    },
  },
}
</script>
<style>
.ql-editor{
  height: 200px!important;
}
</style>
