<template>
  <b-modal
    :id="modalId"
    ok-disabled
    cancel-disabled
    hide-footer
    :title="$t('tests.choose_file_for_body')"
  >
    <tree-collapse
      class="min-w"
      :all-options="false"
      :folder="GET_FOLDER_LIST"
      @show-file="onShowFile"
      @to-right="selectFile"
    />
    <show-file-modal
      :src="selected_file"
      @hide="onHideFile"
    />
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import TreeCollapse from '@/components/TreeCollapse.vue'
import showFileModal from '../../../AddContent/components/showFileModal.vue'

export default {
  components: {
    BModal,
    TreeCollapse,
    showFileModal,
  },
  props: {
    modalId: {
      type: String, required: false, default: 'select-content-modal',
    },
  },
  data() {
    return {
      selected_file: '',
    }
  },
  computed: {
    ...mapGetters('content', ['GET_FOLDER_LIST', 'GET_ONE_FOLDER']),
  },
  mounted() {
    this.FETCH_FOLDERS_LIST()
  },
  methods: {
    ...mapActions('content', [
      'FETCH_FOLDERS_LIST',
      'FETCH_ONE_FOLDER',
      'DELETE_FOLDERS',
    ]),
    onShowFile(value) {
      if (value.file) {
        this.selected_file = value.file
      } else {
        this.$nextTick(() => {
          this.selected_file = value.url
        })
      }
      this.$bvModal.show('ShowContent')
    },
    onHideFile() {
      this.src = null
    },
    selectFile(e) {
      this.$emit('fileSelected', {
        url: e.url ? e.url : e.file,
        data_type: e.data_type,
        id: e.id,
      })
    },
  },
}
</script>

<style>

</style>
